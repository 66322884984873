import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Resizer from 'react-image-file-resizer'
import { useDropzone } from 'react-dropzone'
import CloseX from '../../static/core/svg/closex.svg'
import './FileDrop.scss'

const messages = {
  init: 'Drag your photo here, or click to select a file',
  'file-too-large': 'Your photo is too large; please try a file less than 4 MB',
  'file-invalid-type': 'Only jpeg and png files allowed',
  error: 'That file could not be read.',
  accept: '',
  processing: 'Processing file...',
}

const basename = 'filedrop'

const FileDrop = ({ callback, inputId }) => {
  const [preview, setPreview] = useState(null)
  const [msg, setMsg] = useState(messages.init)

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setMsg(messages.processing)
      if (preview) setPreview(null)
      if (acceptedFiles.length > 1) {
        acceptedFiles.shift()
      }
      Resizer.imageFileResizer(
        acceptedFiles[0],
        2048,
        2048,
        'JPEG',
        60,
        0,
        (blob) => {
          const reader = new FileReader()
          reader.onload = () => {
            setPreview(reader.result)
            callback(reader.result)
            setMsg(messages.accept)
          }
          reader.onerror = () => {
            setMsg(messages.error)
          }
          reader.readAsDataURL(blob)
        },
        'blob',
      )
    },
    [callback, preview],
  )

  const onDropRejected = useCallback(
    (rejectedFiles) => {
      setPreview(null)
      if (rejectedFiles.length > 1) {
        rejectedFiles.shift()
      }
      const { code, message } = rejectedFiles[0].errors[0]
      setMsg(`Error: ${code in messages ? messages[code] : message}`)
      callback(null)
    },
    [callback],
  )

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDropAccepted,
    onDropRejected,
  })

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      setPreview(null)
    },
    [setPreview],
  )

  function handleDelete() {
    setMsg(messages.init)
    acceptedFiles.pop()
    setPreview(null)
    callback(null)
  }

  const wrapperClasses = [
    basename,
    !!preview ? 'has-img' : '',
    !!isDragActive ? 'is-drag' : '',
  ]
    .filter((x) => x)
    .join(' ')

  return (
    <section className={wrapperClasses}>
      {!!preview && (
        <div className={`${basename}-thumb ${basename}-abs`}>
          <img
            className={`${basename}-thumb-img`}
            src={preview}
            alt="preview uploaded file"
          />
        </div>
      )}
      <div {...getRootProps({ className: `${basename}-zone ${basename}-abs` })}>
        <input {...getInputProps()} id={inputId} name={inputId} />
        {msg}
      </div>
      {!!preview && (
        <button
          className={`${basename}-delete`}
          onClick={handleDelete}
          aria-label="remove image"
        >
          <CloseX className={`${basename}-delete-x`} />
        </button>
      )}
    </section>
  )
}

FileDrop.propTypes = {
  callback: PropTypes.func.isRequired,
  inputId: PropTypes.string.isRequired,
}

export default FileDrop
